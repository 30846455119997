import { SocialNetwork } from '../interfaces/social-network.interface';

export const SocialNetworks: SocialNetwork[] = [
  {
    name: 'instagram',
    link: '',
    size: 'xl2',
    mobileSize: 'l',
  },
  {
    name: 'telegram',
    link: '',
    size: 'xl2',
    mobileSize: 'l',
  },
  {
    name: 'linkedIn',
    link: '',
    size: 'xl2',
    mobileSize: 'l',
  },
  {
    name: 'twitter',
    link: '',
    size: 'xl2',
    mobileSize: 'l',
  },
];
