import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  input,
  signal,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ControlType, MaxNumberLength, MinNumberLength } from '@shared/models';
import { ValidationMessageComponent } from '@shared/components';

@Component({
  selector: 'ui-input',
  standalone: true,
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgTemplateOutlet,
    FormsModule,
    ValidationMessageComponent,
    TranslateModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent<T, R> implements ControlValueAccessor, Validator {
  /**
   * Control type
   */
  controlType = input<ControlType>('text');

  /**
   * Input autocomplete
   */
  autocomplete = input<string>('');

  /**
   * Icon of input
   */
  iconTemplate = input<TemplateRef<R> | null>(null);

  /**
   * Indicates if input is non-editable and readonly
   */
  isReadonly = input<boolean>(false);

  /**
   * Placeholder for input
   */
  placeholder = input<string>('');
  min = input<number>(MinNumberLength);
  max = input<number>(MaxNumberLength);

  value = signal<T | null>(null);
  control = signal<AbstractControl | null>(null);

  onTouch: () => void;
  onChange: (value: T | null) => void;

  writeValue(value: T): void {
    this.value.set(value);
  }

  registerOnChange(fn: (value: T | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control.set(control);

    return null;
  }
}
