import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { VALIDATION_MESSAGES } from '@shared/models';

@Component({
  selector: 'ui-validation-message',
  standalone: true,
  templateUrl: './validation-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class ValidationMessageComponent {
  errors = input.required<ValidationErrors | null | undefined>();

  errorMessage = computed(() => this._getErrorMessage);

  private _translateService = inject(TranslateService);

  private get _getErrorMessage(): string | null {
    const errorsList = this.errors();

    if (errorsList) {
      const errors = Object.keys(errorsList) as Array<keyof typeof VALIDATION_MESSAGES>;
      const errorMessageKey = errors.find(error => VALIDATION_MESSAGES[error]);

      if (errorMessageKey) {
        return this._translateService.instant(VALIDATION_MESSAGES[errorMessageKey]);
      }

      return this._translateService.instant(`validationMessage.${errors[0]}`);
    }

    return null;
  }
}
