<ng-select
  class="ui-select"
  [items]="items()"
  [bindLabel]="bindLabel()"
  [placeholder]="placeholder() | translate"
  [clearable]="false"
  [searchable]="false"
  [multiple]="multiple()"
  (blur)="onTouched()"
  (change)="onChange($event)"
  [class.invalid]="control()?.invalid && control()?.touched"
/>
@if (control()?.invalid && control()?.touched) {
  <ui-validation-message [errors]="control()?.errors" />
}
