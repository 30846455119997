import { UserTypes } from '@shared/models';

export const USER_TYPES = [
  {
    id: UserTypes.WEBMASTER,
    name: 'Webmaster',
  },
  {
    id: UserTypes.ADVERTISER,
    name: 'Advertiser',
  },
];
