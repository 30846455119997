export const VALIDATION_MESSAGES = {
  required: 'validationMessages.required',
  passwordNotMatch: 'validationMessages.passwordNotMatch',
  invalidEmail: 'validationMessages.invalidEmail',
  invalidLink: 'validationMessages.invalidLink',
  min: 'validationMessages.min',
  max: 'validationMessages.max',
  passwordMinLength: 'validationMessages.passwordMinLength',
  containsLowerCase: 'validationMessages.containsLowerCase',
  containsNumber: 'validationMessages.containsNumber',
  containsSpecialCharacter: 'validationMessages.containsSpecialCharacter',
  containsUpperCase: 'validationMessages.containsUpperCase',
};
