import { ChangeDetectionStrategy, Component, HostBinding, computed, input } from '@angular/core';
import { NgClass } from '@angular/common';

import { ButtonSize } from '@shared/models';

@Component({
  selector: 'ui-button',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class ButtonComponent {
  @HostBinding('attr.tabindex') tabindex = '-1';
  /**
   * Button text, must be translation key
   */
  label = input.required<string>();

  /**
   * Button size
   */
  size = input<ButtonSize>('l');

  /**
   * Button type
   */
  type = input<string>('button');

  /**
   * If button has disabled state
   */
  disabled = input<boolean>(false);

  /**
   * If button should take full width
   */
  fullWidth = input<boolean>(false);

  classList = computed<string[]>(() => {
    const classList = [`ui-button_${this.size()}`];

    if (this.fullWidth()) {
      classList.push('ui-button_full-width w-100 justify-content-center');
    }

    return classList;
  });
}
