import { Schema } from '../interfaces/schema.interface';

export const SCHEMAS: { forEveryone: Schema; forAffiliates: Schema; forAdvertisers: Schema } = {
  forEveryone: {
    name: 'content.forEveryone',
    items: [
      {
        name: 'forEveryone.expertiseExchange',
        icon: 'expertise-exchange',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forEveryone.fastPerformance',
        icon: 'fast-performance',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forEveryone.handyTools',
        icon: 'handy-tools',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forEveryone.multilingualSupport',
        icon: 'multilingual-support',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forEveryone.statisticsDetailing',
        icon: 'statistics-detailing',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forEveryone.legalSupport',
        icon: 'legal-support',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forEveryone.helpWithSEO',
        icon: 'help-with-seo',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forEveryone.giftShop',
        icon: 'gift-shop',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
    ],
  },
  forAffiliates: {
    name: 'content.forAffiliates',
    mobileName: 'content.forAffiliates',
    items: [
      {
        name: 'forAffiliates.exclusiveOffers',
        icon: 'exclusive-offers',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forAffiliates.individualTerms',
        icon: 'individual-terms',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forAffiliates.infrastructureForWork',
        icon: 'infrastructure-for-work',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forAffiliates.apps',
        icon: 'apps',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forAffiliates.uniquePaymentSolution',
        icon: 'unique-payment-solution',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
    ],
  },
  forAdvertisers: {
    name: 'content.forAdvertisers',
    mobileName: 'content.forAdvertisersMobile',
    items: [
      {
        name: 'forAdvertisers.highQualityTraffic',
        icon: 'high-quality-traffic',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forAdvertisers.assistance',
        icon: 'assistance',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forAdvertisers.directIntegration',
        icon: 'direct-integration',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forAdvertisers.ownMediaBuying',
        icon: 'own-media-buying',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
      {
        name: 'forAdvertisers.powerfulSystem',
        icon: 'powerful-system',
        iconSize: 'xl3',
        iconMobileSize: 'm',
      },
    ],
  },
};
